<template>
  <v-container class="my-4">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="pl-0" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-form ref="form" @submit.prevent="create()">
            <v-card-title>
              <div class="primary--text">Yeni Ders Oluştur</div>
              <v-spacer />
              <v-btn small elevation="0" @click="$router.go(-1)"
                >GERİ DÖN</v-btn
              >
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="name"
                    v-model="form.name"
                    label="Ders Adı"
                    name="name"
                    type="text"
                    required
                    :rules="requiredRule"
                /></v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.startDate"
                        label="Başlangıç Tarihi"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="requiredRule"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.startDate"
                      @input="startDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    ref="startTimeMenu"
                    v-model="startTimePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="form.startTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.startTime"
                        label="Başlangıç Saati"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="requiredRule"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="startTimePicker"
                      v-model="form.startTime"
                      full-width
                      format="24hr"
                      @click:minute="$refs.startTimeMenu.save(form.startTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="zoomLink"
                    v-model="form.zoomLink"
                    label="Zoom Bağlantısı"
                    name="zoomLink"
                    type="text"
                /></v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    id="youtubeId"
                    v-model="form.youtubeId"
                    label="YouTube ID"
                    name="youtubeId"
                    type="text"
                /></v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    id="vimeoId"
                    v-model="form.vimeoId"
                    label="Vimeo ID"
                    name="vimeoId"
                    type="text"
                /></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label class="v-label theme--light d-block mb-2"
                    >Açıklama</label
                  >
                  <ckeditor
                    :editor="editor"
                    :config="editorConfig"
                    v-model="form.desc"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                elevation="0"
                type="submit"
                :disabled="submitDisabled"
                class="mr-2 px-4"
                >OLUŞTUR</v-btn
              >
              <v-btn :to="{ name: 'admin-courses' }" elevation="0"
                >İPTAL ET</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";
import "@/types";

export default Vue.extend({
  metaInfo: {
    title: "Yeni Ders Oluştur"
  },

  components: {
    ckeditor: CKEditor.component
  },

  data: () => ({
    course: {
      id: "",
      name: ""
    },
    form: {
      name: "",
      desc: "",
      startDate: null,
      startTime: null,
      zoomLink: "",
      youtubeId: "",
      vimeoId: ""
    },
    startDatePicker: false,
    startTimePicker: false,
    submitDisabled: false,
    editor: ClassicEditor,
    editorConfig: {
      language: "tr",
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo"
      ]
    }
  }),

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Eğitimler",
          exact: true,
          to: { name: "admin-courses" }
        },
        {
          text: this.course.name,
          exact: true,
          to: { name: "admin-course-edit" }
        },
        {
          text: "Dersler"
        }
      ];
    },

    requiredRule() {
      return [v => !!v || "Zorunlu alan"];
    },

    urlCodeRules() {
      return [
        v => !!v || "Zorunlu alan",
        v => /^[a-z0-9-]*$/.test(v) || "Geçersiz URL kodu"
      ];
    },

    previewImgRules() {
      return [
        v => !!v || "Zorunlu alan",
        v => !v || v.size < 2097152 || "Dosya boyutu 2 MB'den küçük olmalıdır."
      ];
    },

    priceRules() {
      return [
        v => !!v || "Zorunlu alan",
        v =>
          !v ||
          /^((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:\.\d{3})?)*((?=.*\d)(?:,\d\d){1}?){0,1}$/gm.test(
            v
          ) ||
          "Geçersiz fiyat. Ör: 1.000 ya da 1.000,00"
      ];
    }
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.form.desc = "";
      this.resetValidation();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    onPreviewImgChange() {
      this.previewImgUrl = this.form.previewImg
        ? URL.createObjectURL(this.form.previewImg)
        : null;
    },

    async create() {
      const form = this.$refs.form;

      if (!form.validate()) {
        return;
      }

      this.submitDisabled = true;

      this.$notify({
        text: "Yeni ders oluşturuluyor. Lütfen bekleyin..."
      });

      try {
        const newLesson = {
          startDate: new Date(`${this.form.startDate} ${this.form.startTime}`),
          desc: this.form.desc,
          disabled: false,
          name: this.form.name,
          zoomLink: this.form.zoomLink,
          youtubeId: this.form.youtubeId,
          vimeoId: this.form.vimeoId,
          courseId: this.course.id
        };

        // Add lesson to database
        await firebase
          .firestore()
          .collection("/apps/kahev-akademi/lessons")
          .add(newLesson);

        this.$notify({
          type: "success",
          title: "İşlem Tamam",
          text: "Ders başarıyla oluşturuldu."
        });

        this.$router.push({ name: "admin-lessons" });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: `Ders oluşturulamadı.`
        });

        throw new Error(`Ders oluşturulamadı. ${err.message}`);
      } finally {
        this.submitDisabled = false;
      }
    },

    async getCourse() {
      try {
        // Get course to database
        const qs = await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/courses/${this.$route.params.id}`)
          .get();

        if (qs.exists) {
          const c = {};
          c.id = qs.id;
          c.name = qs.data()?.name;
          c.urlCode = qs.data()?.urlCode;
          c.disabled = qs.data()?.disabled;
          c.instructor = qs.data()?.instructor;
          c.desc = qs.data()?.desc;
          c.img = qs.data()?.img;
          c.price = qs.data()?.price;
          c.startDate = qs.data()?.startDate
            ? qs.data()?.startDate.toDate()
            : null;
          c.finishDate = qs.data()?.finishDate
            ? qs.data()?.finishDate.toDate()
            : null;

          this.course = c;
        }
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: `Eğitim bilgilerini alınamadı.`
        });

        throw new Error(`Eğitim bilgileri alınamadı. ${err.message}`);
      }
    }
  },

  async mounted() {
    await this.getCourse();
  }
});
</script>

<style lang="scss" scoped></style>
